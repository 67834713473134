import React from 'react'
import { Box } from '@mui/material'

const CustomScrollbar = ({ children, maxHeight = '100%', maxWidth = '100%', ...props }) => {
  return (
    <Box
      sx={{
        maxHeight,
        maxWidth,
        overflowY: 'auto',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          width: '0.4em',
          height: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(0,0,0,0.1)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255,255,255,.3)',
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,.5)'
          }
        },
        '&::-webkit-scrollbar-corner': {
          background: 'rgba(0,0,0,0.1)'
        },
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(255,255,255,.3) rgba(0,0,0,0.1)',
        '& > *': {
          minWidth: 'fit-content'
        },
        ...props.sx
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default CustomScrollbar
