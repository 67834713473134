// ** Next Import
import Link from 'next/link'

// ** MUI Imports
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Custom Icon Import
import Icon from 'src/@core/components/icon'

// ** Configs
import themeConfig from 'src/configs/themeConfig'

// ** Styled Components
const MenuHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(4.5),
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight
}))

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: '24px',
  fontSize: '1.375rem !important',
  color: theme.palette.text.primary,
  transition: 'opacity .25s ease-in-out, margin .25s ease-in-out'
}))

const LinkStyled = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
})

const VerticalNavHeader = props => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    saveSettings,
    collapsedNavWidth,
    toggleNavVisibility,
    navigationBorderWidth,
    menuLockedIcon: userMenuLockedIcon,
    navMenuBranding: userNavMenuBranding,
    menuUnlockedIcon: userMenuUnlockedIcon
  } = props

  // ** Hooks & Vars
  const theme = useTheme()
  const { mode, navCollapsed } = settings
  const menuCollapsedStyles = navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 }

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userNavMenuBranding) {
        return 0
      } else {
        return (collapsedNavWidth - navigationBorderWidth - 32) / 8
      }
    } else {
      return 4.5
    }
  }

  const conditionalColors = () => {
    if (mode === 'semi-dark') {
      return {
        '& .MuiTypography-root, & .MuiIconButton-root': {
          color: `rgba(${theme.palette.customColors.dark}, 0.87)`
        }
      }
    } else {
      return {
        '& .MuiTypography-root, & .MuiIconButton-root': {
          color: 'text.primary'
        }
      }
    }
  }
  const MenuLockedIcon = () => userMenuLockedIcon || <Icon icon='tabler:circle-dot' />
  const MenuUnlockedIcon = () => userMenuUnlockedIcon || <Icon icon='tabler:circle' />

  return (
    <MenuHeaderWrapper className='nav-header' sx={{ pl: menuHeaderPaddingLeft(), ...conditionalColors() }}>
      {userNavMenuBranding ? (
        userNavMenuBranding(props)
      ) : (
        <LinkStyled href='/'>
          {/* icon  SVG or logo */}
          <Box sx={{ width: '2.6rem', height: '2.5rem', display: navCollapsed && !navHover ? '' : 'none' }}>
            <svg
              version='1.0'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 522.000000 500.000000'
              preserveAspectRatio='xMidYMid meet'
            >
              <g
                transform='translate(0.000000,500.000000) scale(0.100000,-0.100000)'
                fill={theme.palette.text.primary}
                stroke='none'
              >
                <path
                  d='M1665 4959 c-443 -256 -781 -633 -969 -1082 -142 -339 -203 -696
                -176 -1037 61 -794 505 -1485 1205 -1878 393 -220 884 -329 1325 -293 147 13
                212 31 109 31 -29 0 -100 7 -157 15 -1026 146 -1853 891 -2085 1880 -204 864
                84 1751 758 2343 39 34 68 61 65 61 -3 0 -36 -18 -75 -40z'
                />
                <path
                  d='M2599 4756 c-808 -117 -1399 -753 -1469 -1583 -33 -383 82 -802 310
                -1137 73 -108 219 -270 313 -348 58 -48 56 -44 -25 65 -159 216 -281 505 -335
                797 -25 133 -25 505 0 636 97 515 371 938 787 1216 194 129 424 225 645 268
                115 22 328 39 405 33 76 -6 25 13 -95 37 -142 28 -399 35 -536 16z'
                />
                <path
                  d='M2736 4205 c-491 -93 -854 -464 -941 -962 -17 -98 -20 -323 -3 -263
                86 310 168 465 339 645 240 251 552 385 895 385 300 0 558 -95 794 -291 56
                -46 60 -48 46 -21 -9 17 -42 63 -74 104 -153 192 -354 321 -601 385 -123 32
                -335 40 -455 18z'
                />
                <path
                  d='M2801 3644 c-88 -24 -191 -84 -258 -151 -35 -35 -63 -66 -63 -71 0
                -4 30 7 68 25 89 43 181 63 294 63 118 0 201 -20 306 -72 99 -49 212 -150 270
                -241 40 -64 91 -182 93 -214 0 -16 1 -16 9 2 5 11 9 54 9 95 2 254 -161 479
                -402 555 -92 29 -236 33 -326 9z'
                />
                <path
                  d='M91 3618 c-15 -47 -49 -217 -67 -333 -22 -143 -29 -484 -15 -641 56
                -591 294 -1170 664 -1609 456 -541 1094 -898 1797 -1006 173 -26 510 -36 675
                -20 493 49 944 207 1342 470 259 170 509 400 681 626 l47 60 -37 -30 c-233
                -191 -341 -270 -501 -364 -417 -244 -864 -379 -1364 -412 -622 -41 -1276 130
                -1815 474 -710 454 -1202 1187 -1352 2017 -36 197 -48 359 -44 593 2 114 2
                207 1 207 -1 0 -6 -15 -12 -32z'
                />
              </g>
            </svg>
          </Box>
          <HeaderTitle
            variant='h6'
            sx={{
              ...menuCollapsedStyles,
              ...(navCollapsed && !navHover ? {} : { ml: 2.5 })
            }}
          >
            <Box sx={{ width: '10.1rem', padding: '1rem' }}>
              <svg
                version='1.0'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 1063.000000 500.000000'
                preserveAspectRatio='xMidYMid meet'
              >
                <g
                  transform='translate(0.000000,500.000000) scale(0.100000,-0.100000)'
                  fill={theme.palette.text.primary}
                  stroke='none'
                >
                  <path
                    d='M1665 4959 c-443 -256 -781 -633 -969 -1082 -142 -339 -203 -696
                  -176 -1037 61 -794 505 -1485 1205 -1878 393 -220 884 -329 1325 -293 147 13
                  212 31 109 31 -29 0 -100 7 -157 15 -1026 146 -1853 891 -2085 1880 -204 864
                  84 1751 758 2343 39 34 68 61 65 61 -3 0 -36 -18 -75 -40z'
                  />
                  <path
                    d='M2599 4756 c-808 -117 -1399 -753 -1469 -1583 -33 -383 82 -802 310
                  -1137 73 -108 219 -270 313 -348 58 -48 56 -44 -25 65 -159 216 -281 505 -335
                  797 -25 133 -25 505 0 636 97 515 371 938 787 1216 194 129 424 225 645 268
                  115 22 328 39 405 33 76 -6 25 13 -95 37 -142 28 -399 35 -536 16z'
                  />
                  <path
                    d='M2736 4205 c-430 -82 -770 -382 -900 -794 -39 -124 -57 -245 -54
                  -363 l3 -103 23 92 c123 496 520 872 1010 958 112 19 305 19 418 0 215 -38
                  407 -129 584 -276 56 -46 60 -48 46 -21 -9 17 -42 63 -74 104 -153 192 -354
                  321 -601 385 -123 32 -335 40 -455 18z'
                  />
                  <path
                    d='M2801 3644 c-88 -24 -191 -84 -258 -151 -35 -35 -63 -66 -63 -71 0
                  -4 30 7 68 25 89 43 181 63 294 63 118 0 201 -20 306 -72 99 -49 212 -150 270
                  -241 40 -64 91 -182 93 -214 0 -16 1 -16 9 2 5 11 9 54 9 95 2 254 -161 479
                  -402 555 -92 29 -236 33 -326 9z'
                  />
                  <path
                    d='M91 3618 c-15 -47 -49 -217 -67 -333 -22 -143 -29 -484 -15 -641 56
                  -592 294 -1169 664 -1609 456 -541 1094 -898 1797 -1006 173 -26 510 -36 675
                  -20 493 49 944 207 1342 470 259 170 509 400 681 626 l47 60 -37 -30 c-233
                  -191 -341 -270 -501 -364 -417 -244 -864 -379 -1364 -412 -622 -41 -1276 130
                  -1815 474 -710 454 -1202 1187 -1352 2017 -36 197 -48 359 -44 593 2 114 2
                  207 1 207 -1 0 -6 -15 -12 -32z'
                  />
                  <path
                    d='M9290 2624 l0 -237 -21 27 c-73 93 -254 98 -356 11 -66 -56 -96 -130
                  -101 -243 -8 -168 43 -283 149 -336 96 -48 235 -31 303 37 l25 25 3 -32 3 -31
                  73 -3 72 -3 0 511 0 510 -75 0 -75 0 0 -236z m-85 -284 c69 -34 104 -119 90
                  -225 -12 -100 -79 -165 -170 -165 -102 0 -165 77 -165 200 0 161 117 252 245
                  190z'
                  />
                  <path
                    d='M4644 2822 c-34 -5 -66 -46 -67 -86 -3 -98 135 -127 173 -37 28 70
                  -28 135 -106 123z'
                  />
                  <path
                    d='M7100 2817 c-156 -44 -235 -190 -184 -340 22 -66 97 -130 232 -200
                  132 -69 168 -97 180 -140 36 -128 -107 -218 -239 -151 -26 13 -63 52 -84 89
                  -9 16 -14 15 -69 -9 -57 -25 -58 -27 -52 -58 15 -74 83 -143 169 -172 64 -21
                  190 -21 258 1 75 24 152 101 173 173 50 169 -13 282 -210 379 -156 76 -204
                  120 -204 186 0 117 171 156 251 58 l24 -30 60 30 c33 16 61 31 63 32 7 6 -28
                  61 -55 87 -66 63 -213 94 -313 65z'
                  />
                  <path
                    d='M9693 2822 c-27 -4 -73 -56 -73 -83 0 -57 62 -109 112 -94 44 14 68
                  45 68 90 0 60 -45 97 -107 87z'
                  />
                  <path
                    d='M2285 2798 c-3 -7 -4 -224 -3 -483 l3 -470 170 -3 c291 -5 401 19
                  500 110 104 95 145 201 145 375 0 220 -95 379 -265 442 -97 36 -539 59 -550
                  29z m497 -171 c105 -51 152 -144 151 -302 0 -120 -23 -192 -81 -252 -65 -67
                  -98 -77 -259 -81 l-143 -4 0 337 0 337 138 -4 c125 -3 142 -6 194 -31z'
                  />
                  <path
                    d='M7690 2585 l0 -115 -55 0 -55 0 0 -65 0 -65 55 0 55 0 0 -250 0 -250
                  70 0 70 0 0 250 0 250 55 0 55 0 0 65 0 65 -55 0 -55 0 0 115 0 115 -70 0 -70
                  0 0 -115z'
                  />
                  <path
                    d='M3455 2477 c-98 -33 -162 -88 -204 -177 -22 -47 -26 -68 -26 -150 1
                  -81 5 -103 27 -152 55 -119 154 -178 298 -178 89 0 158 21 216 65 34 26 94
                  100 94 116 0 4 -26 21 -57 38 l-57 31 -38 -44 c-108 -126 -293 -91 -327 62
                  l-9 42 250 0 251 0 -5 68 c-9 125 -70 218 -172 263 -61 26 -184 35 -241 16z
                  m196 -140 c31 -20 77 -92 67 -103 -3 -2 -80 -3 -172 -2 l-167 3 23 44 c31 58
                  75 81 154 81 47 0 69 -5 95 -23z'
                  />
                  <path
                    d='M4116 2475 c-87 -31 -136 -91 -136 -167 0 -54 16 -92 52 -124 26 -24
                  111 -69 170 -90 68 -24 98 -51 98 -89 0 -53 -39 -78 -114 -73 -50 4 -63 9 -86
                  35 -15 17 -29 36 -32 43 -3 10 -18 6 -57 -16 -29 -15 -55 -34 -58 -41 -7 -19
                  38 -73 87 -101 41 -25 54 -27 155 -27 102 0 113 2 157 27 85 51 118 145 80
                  230 -25 58 -66 89 -182 135 -112 46 -144 77 -120 120 29 51 117 55 162 8 13
                  -14 25 -25 28 -25 3 0 27 15 53 34 l48 34 -31 35 c-53 61 -181 85 -274 52z'
                  />
                  <path
                    d='M5073 2470 c-65 -26 -123 -89 -153 -166 -32 -81 -32 -224 -2 -304 46
                  -119 132 -180 255 -180 79 0 128 18 175 62 l32 32 0 -65 c-1 -90 -25 -141 -79
                  -162 -81 -33 -189 -14 -213 37 -11 25 -15 26 -90 26 -43 0 -78 -4 -78 -9 0
                  -27 27 -81 58 -113 55 -57 106 -73 242 -73 106 0 120 2 172 28 62 30 89 60
                  115 129 15 37 18 95 21 401 l3 357 -75 0 -76 0 0 -41 0 -41 -28 29 c-64 69
                  -182 91 -279 53z m204 -121 c72 -27 107 -92 106 -200 0 -120 -67 -199 -167
                  -199 -111 0 -166 67 -166 200 0 61 5 86 23 121 37 72 129 107 204 78z'
                  />
                  <path
                    d='M5992 2472 c-30 -11 -71 -36 -93 -58 l-39 -38 0 47 0 47 -70 0 -70 0
                  0 -315 0 -315 74 0 75 0 3 213 c3 201 4 214 25 243 31 43 80 64 148 64 119 0
                  128 -21 133 -303 l4 -218 71 3 72 3 0 245 c0 237 -1 247 -23 293 -34 67 -91
                  99 -186 104 -52 3 -85 -2 -124 -15z'
                  />
                  <path
                    d='M10178 2471 c-219 -70 -307 -329 -173 -513 65 -89 162 -138 276 -138
                  164 0 294 96 335 247 33 124 -3 241 -102 332 -92 84 -215 110 -336 72z m196
                  -139 c16 -9 43 -34 60 -55 28 -35 31 -47 34 -115 3 -71 1 -80 -27 -124 -75
                  -118 -237 -118 -312 -1 -24 39 -29 57 -29 111 0 72 17 115 59 156 58 56 148
                  67 215 28z'
                  />
                  <path
                    d='M4595 2457 c-3 -7 -4 -147 -3 -312 l3 -300 73 -3 72 -3 0 316 0 315
                  -70 0 c-48 0 -72 -4 -75 -13z'
                  />
                  <path
                    d='M8062 2243 c3 -222 4 -230 29 -284 47 -99 125 -139 270 -139 149 0
                  222 39 271 145 22 47 23 65 26 278 l3 227 -70 0 -70 0 -3 -212 c-3 -240 -10
                  -267 -73 -294 -44 -18 -126 -18 -170 0 -62 26 -69 54 -73 294 l-4 212 -70 0
                  -70 0 4 -227z'
                  />
                  <path d='M9640 2155 l0 -315 75 0 75 0 0 315 0 315 -75 0 -75 0 0 -315z' />
                </g>
              </svg>
            </Box>
          </HeaderTitle>
        </LinkStyled>
      )}

      {hidden ? (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={toggleNavVisibility}
          sx={{
            p: 0,
            backgroundColor: 'transparent !important',
            color: `${
              mode === 'semi-dark' ? `rbga(${theme.palette.customColors.dark}, 0.6)` : theme.palette.text.secondary
            } !important`
          }}
        >
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </IconButton>
      ) : userMenuLockedIcon === null && userMenuUnlockedIcon === null ? null : (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={() => saveSettings({ ...settings, navCollapsed: !navCollapsed })}
          sx={{
            p: 0,
            backgroundColor: 'transparent !important',
            '& svg': {
              fontSize: '1.25rem',
              ...menuCollapsedStyles,
              transition: 'opacity .25s ease-in-out'
            }
          }}
        >
          {navCollapsed ? MenuUnlockedIcon() : MenuLockedIcon()}
        </IconButton>
      )}
    </MenuHeaderWrapper>
  )
}

export default VerticalNavHeader
