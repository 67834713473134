import { useState, Fragment, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSession, signOut } from 'next-auth/react'

import { styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Icon from 'src/@core/components/icon'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import { Box } from '@mui/material'
import WorkerAvatar from 'src/components/shared/WorkerAvatar'

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    '& .MuiBox-root, & .MuiBox-root svg, & .MuiTypography-root': {
      color: theme.palette.primary.main
    }
  },
  '& .MuiBox-root': {
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  '& svg': {
    fontSize: '1.25rem'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent'
  }
}))

const UserDropdown = props => {
  const { saveSettings, settings } = props
  const router = useRouter()
  const { data: session } = useSession()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    signOut({ callbackUrl: '/', redirect: false }).then(() => {
      router.push('/')
    })
    handleClose()
  }

  const handleMyProfileClick = () => {
    router.push('/profile')
    handleClose()
  }

  useEffect(() => {
    if (anchorEl) {
      const menuItems = anchorEl.querySelectorAll('.MuiMenuItem-root')
      menuItems.forEach(item => item.blur())
    }
  }, [anchorEl])

  const notifications = [
    {
      id: '1',
      type: 'message',
      title: 'New Message from John',
      description: 'Hey, can we discuss the project?',
      timestamp: '2023-08-08T14:30:00Z',
      priority: 'high',
      project: 'Website Redesign',
      isRead: false,
      avatarImg: '/path/to/john-avatar.jpg'
    },
    {
      id: '2',
      type: 'alert',
      title: 'Server Update Required',
      description: 'Critical security update available',
      timestamp: '2023-08-08T15:45:00Z',
      priority: 'urgent',
      project: 'Infrastructure',
      isRead: false,
      avatarIcon: 'tabler:alert-triangle'
    }
  ]

  return (
    <Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, p: 2 }}>
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <NotificationDropdown notifications={notifications} settings={settings} />
        <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
          <WorkerAvatar
            showTooltip={false}
            name={session?.user?.name || ''}
            userId={session?.user?.id || ''}
            size={40}
          />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItemStyled onClick={handleMyProfileClick}>
            <Box component={Icon} icon='tabler:user' />
            <Typography>My Profile</Typography>
          </MenuItemStyled>
          <MenuItemStyled onClick={handleLogout}>
            <Box component={Icon} icon='tabler:logout' />
            <Typography>Sign Out</Typography>
          </MenuItemStyled>
        </Menu>
      </Box>
    </Fragment>
  )
}

export default UserDropdown
