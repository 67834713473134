const all_roles = ['admin', 'team_coordinator', 'technical_coordinator', 'designer', 'commercial']

const navigation = () => {
  return [
    {
      path: `/admin`,
      icon: 'mdi:administrator',
      title: 'Admin',
      acl: ['admin']
    },
    {
      path: `/overview/${new Date().getFullYear()}`,
      icon: 'wpf:survey',
      title: 'Overview',
      acl: all_roles
    },
    {
      path: `/overview-new`,
      icon: 'mdi:new-box',
      title: 'Overview [NEW]',
      acl: all_roles
    },
    {
      path: '/dashboard',
      icon: 'material-symbols:bar-chart-4-bars-rounded',
      title: 'Dashboard',
      acl: all_roles
    },
    {
      path: `/projects`,
      icon: 'ic:round-view-list',
      title: 'To assign',
      acl: ['team_coordinator']
    },
    {
      path: `/statistics`,
      icon: 'streamline:programming-browser-dashboard-app-code-apps-programming-window-dashboard-performance',
      title: 'Monthly production',
      acl: ['team_coordinator', 'technical_coordinator', 'designer']
    },
    {
      path: `/myProjects`,
      icon: 'mdi:folder-open',
      title: 'My projects',
      acl: ['technical_coordinator', 'designer']
    },
    {
      path: `/projectReview`,
      icon: 'healthicons:magnifying-glass-outline',
      title: 'Project review',
      acl: ['technical_coordinator']
    },
    {
      path: `/help`,
      icon: 'material-symbols:help-outline',
      title: 'Help',
      acl: all_roles
    },

    {
      path: `/stats`,
      icon: 'mdi:chart-line',
      title: 'Statistics',
      acl: ['admin']
    },
    {
      path: `http://library.dsproject.pt:8081/`,
      icon: 'mdi:image-multiple',
      title: 'Library [BETA]',
      acl: all_roles,
      externalLink: true,
      openInNewTab: true
    },
    {
      path: `https://planka.dsproject.pt/`,
      icon: 'mdi:trello',
      title: 'Planka',
      acl: all_roles,
      externalLink: true,
      openInNewTab: true
    }
  ]
}

export default navigation
