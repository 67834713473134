// ** React Imports
import { useContext } from 'react'

// ** Component Imports
import { AbilityContext } from 'src/layouts/components/acl/Can'

import { useSession } from 'next-auth/react'

const CanViewNavLink = props => {
  const { children, navLink } = props
  const { data: session } = useSession()

  if (navLink.acl) {
    return (
      <>
        {navLink.acl.some(role => session.user.roles.includes(role)) | session.user.roles.includes('admin')
          ? children
          : ''}
      </>
    )
  }

  return children
}

export default CanViewNavLink
